<template>
  <div class="home">
    <div>
      <div class="carousel-container">
        <!-- 这里是内容 -->
        <b-carousel
            id="carousel-fade"
            style="text-shadow: 0px 0px 2px #000"
            fade
            indicators:none
        >
          <b-carousel-slide
              v-for="item in banner"
              :key="item.id"
              :img-src="item.img"
          ></b-carousel-slide>
        </b-carousel>
      </div>
      <div class="float-solution-header">
        <div style="color: white">
          <h1 class="top-title">机械装备行业</h1>
          <br>
          <span
              style="font-size: 18px">机械装备行业运营决策分析平台，通过大数据信息分析、传递和透明融合打通和支撑企业运营管理和业财经营活动，实现数字化转型管理体系的顶层建设。</span>
        </div>
        <br>
        <br>
        <el-button class="image-button">&nbsp;&nbsp;方案咨询 &nbsp;&nbsp; →
        </el-button>
      </div>
    </div>
    <!-- 三百家企业的共同选择 -->
    <section class="partner" style="padding-top: 0px;margin-top: 0px">
      <div class="solution-background-style">
        <div class="boss-content">
        <div style="text-align: center">
          <div style="padding-top: 80px;margin-right: 225px">
            <div class="container">
              <h1 class="solution-title-img">行业痛点</h1>
            </div>
            <div class="float-solution-customer">
              <img :src="successUrl3"/>
            </div>
          </div>
        </div>
        </div>
        <div class="float-solution-div">
          <div class="boss-content">
          <b-container fluid="xl" style="margin-bottom: 0px">
            <div style="margin-top: 300px">
              <el-row :gutter="10">
                <el-col :md="24"  class="solution-myCol">
                  <div class="grid-content bg-purple-light">
                    <el-row class="solution-myrow">
                      <div style="padding-right: 20px;text-align: center;height: 100px" class="float-reason-div">
                        <img :src="solutionURL1">
                      </div>
                      <div style="margin-left: 50px;margin-right: 50px;text-align: center">
                        <p style="font-size: 18px">
                          随着市场快速发展，传统的线下管理开发的方式已不利于装备制造企业的快速发展，需建立统一标准的产品开发管理平台，将产品开发的全过程串起来，实现集成化、可视化,
                          同时建立标准化流程，使得开发全过程高效开展。由于装备制造企业信息平台较多，各个平台之间相互独立,数据无法交互，流程缺乏统一管理亟需一套—体化整合平台来构建公司管理系统。
                        </p>
                      </div>
                    </el-row>
                  </div>
                </el-col>
              </el-row>
            </div>
          </b-container>
          </div>
        </div>
      </div>
    </section>
    <section class="partner" style="padding-bottom: 0px;margin-top: 20px">
      <div class="img-reason-one">
        <div class="boss-content">
          <b-container fluid="xl" style="margin-bottom: 0px">
            <div class="float-low">
              <div class="container">
                <br>
                <br>
                <br>
                <h1 class="solution-title-img">方案特点</h1>
              </div>
              <div class="float-solution-peculiarity">
                <img :src="successUrl3"/>
              </div>
            </div>
            <br>
            <br>
            <br>
            <div style="margin-top: 300px">
              <el-row :gutter="40">
                <el-col :md="12" >
                  <div class="grid-content bg-purple-light">
                    <br>
                    <el-row class="myrow">
                      <el-col :md="11" class="product-solution-mycol">
                        <el-row :gutter="10" style="margin: 0">
                          <el-col :md="8" >
                            <div style="padding-right: 20px;margin-left: 20px;text-align: center;height: 100px;width: 50%"
                                 class="float-solution-peculiarity-div">
                              <img :src="solutionPeculiarityUrl1" class="image-solution-style">
                            </div>
                          </el-col>
                          <el-col :md="16">
                            <div class="title-solution">
                              <h1 style="font-size: 25px;font-weight: bold;padding: 0">构建信息一体化</h1>
                            </div>
                          </el-col>
                        </el-row>
                        <div style="margin-right: 20px;margin-left: 20px;">
                          <p style="font-size: 13px">
                            集成研发项目管理平台，改进企业经营和管理、推动企业变革，促进装备制造企业进一步提升产品开发能力。
                          </p>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
                <el-col  :md="12">
                  <div class="grid-content bg-purple-light">
                    <br>
                    <el-row class="myrow">
                      <el-col :md="5"  class="product-solution-mycol">
                        <el-row :gutter="10" style="margin: 0">
                          <el-col :md="8">
                            <div style="padding-right: 20px;margin-left: 20px;text-align: center;height: 100px;width: 50%"
                                 class="float-solution-peculiarity-div">
                              <img :src="solutionPeculiarityUrl2" class="image-solution-style">
                            </div>
                          </el-col>
                          <el-col :md="16">
                            <div class="title-solution">
                              <h1 style="font-size: 25px;font-weight: bold">安全实施规范化</h1>
                            </div>
                          </el-col>
                        </el-row>
                        <div style="margin-right: 20px;margin-left: 20px">
                          <p style="font-size: 13px">
                            统筹统一的集成架构规范，正确建立与外部系统的数据集成、流程集成、精确对接业务流转，确保业务的完整准确性，满足现有的业务需求。
                          </p>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
              </el-row>
              <br>
              <br>
              <el-row :gutter="40">
                <el-col :md="12" >
                  <div class="grid-content bg-purple-light">
                    <br>
                    <el-row class="myrow">
                      <el-col :md="5" class="product-solution-mycol">
                        <el-row :gutter="10" style="margin: 0">
                          <el-col :md="8" >
                            <div style="padding-right: 20px;margin-left: 20px;text-align: center;height: 100px;width: 50%"
                                 class="float-solution-peculiarity-div">
                              <img :src="solutionPeculiarityUrl3" class="image-solution-style">
                            </div>
                          </el-col>
                          <el-col :md="16">
                            <div class="title-solution">
                              <h1 style="font-size: 25px;font-weight: bold">业务流程统一化</h1>
                            </div>
                          </el-col>
                        </el-row>
                        <div style="margin-right: 20px;margin-left: 20px;">
                          <p style="font-size: 13px">
                            建立各级单位准则的业务处理流程体系，助力用户搭建完整的业务流程，提高管理效率、业务监管透明度，搭建更好、更快、便捷的管理体系。
                          </p>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
                <el-col :md="12" >
                  <div class="grid-content bg-purple-light">
                    <br>
                    <el-row class="myrow">
                      <el-col :md="5"  class="product-solution-mycol">
                        <el-row :gutter="10" style="margin: 0">
                          <el-col  :md="8">
                            <div style="padding-right: 20px;margin-left: 20px;text-align: center;height: 100px;width: 50%"
                                 class="float-solution-peculiarity-div">
                              <img :src="solutionPeculiarityUrl4" class="image-solution-style">
                            </div>
                          </el-col>
                          <el-col :md="16">
                            <div class="title-solution">
                              <h1 style="font-size: 25px;font-weight: bold">审批流程透明化</h1>
                            </div>
                          </el-col>
                        </el-row>
                        <div style="margin-right: 20px;margin-left: 20px;">
                          <p style="font-size: 13px">
                            所有的业务流转记录可追溯，从线下的纸质存档转为线上的流程单据记录，降低工作量，提高工作效率。
                          </p>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
              </el-row>
            </div>
            <br>
            <br>
            <br>
          </b-container>
        </div>
      </div>
    </section>
    <section class="partner" style="padding-bottom: 0px;margin-top: 0px">
      <div class="solution-DI-backImg">
        <b-container fluid="xl" style="margin-bottom: 0px">
          <div style="margin: 0 auto;text-align: center;" class="float-low">
            <br>
            <br>
            <br>
            <br>
            <div class="container">
              <h1 class="solution-title-img">总体架构</h1>
              <br>
              <span style="font-size: 20px;">实现企业门户，打通事业孤岛</span>
            </div>
            <div class="float-solution-all">
              <img :src="successUrl3"/>
            </div>
          </div>
          <br>
          <br>
          <br>
          <br>
          <br>
          <b-row class="mb-4" cols="3" cols-sm="3" cols-md="4" cols-lg="6">
            <div>
              <img :src="solutionTotalUrl" style="width: 1130px;height: 700px">
            </div>
          </b-row>
        </b-container>
      </div>
      <div
          style="width: 100%;background-image: url('/src/assets/images/home_slices/Group 1754.png');padding-bottom: 0px">
      </div>
    </section>
    <section class="partner" style="padding-bottom: 0px;margin-top: 20px;height: 600px">
        <b-container fluid="xl" style="margin-bottom: 0px">
          <div class="float-low">
            <div class="container" style="margin-top: 50px">
              <h1 class="solution-title-img">业务价值</h1>
            </div>
            <diV>
              <p style="font-size: 20px">
                以BossMa低代码开发平台、壹公里引用系统集成平台为核心，以壹公里企业为门户、BossBI数据设计中心和BossDI数据中台为辅助，构建制造业企业的一体化整合管理平台。</p>
            </diV>
            <div class="float-solution-business">
              <img :src="successUrl3"/>
            </div>
          </div>
          <div>
            <el-row :gutter="40">
              <el-col  :md="12" >
                <div class="grid-content bg-purple-light">
                  <el-row class="myrow">
                    <el-col :md="11"  class="product-solution-mycol">
                      <el-row :gutter="10" style="margin: 0">
                        <el-col  :md="8"  style="height: 100%">
                          <div style="padding-right: 20px;margin-left: 30px;text-align: center;height: 100px;width: 50%">
                            <img :src="solutionBusinessUrl1" style="margin-top: 50px">
                          </div>
                        </el-col>
                        <el-col  :md="16">
                          <div style="width: 100%;margin:50px 20px 20px 0;">
                            <h1 style="font-size: 25px;font-weight: bold;padding: 0">信息化数据孤岛</h1>
                          </div>
                          <div style="margin-right: 20px;margin-left: 0;margin-top: 20px">
                            <p style="font-size: 12px">
                              装备制造企业信息平台叫多，各个平台之间相互独立，数据无法交互，流程缺乏统一管理，通过实施企业门户，OAM及OID，通过单点登入及统一的身份认证打通信息化孤岛，统筹管理企业IT信息平台。
                            </p>
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
              <el-col  :md="12">
                <div class="grid-content bg-purple-light">
                  <el-row class="myrow">
                    <el-col :md="11" class="product-solution-mycol">
                      <el-row :gutter="10" style="margin: 0">
                        <el-col :md="8"  style="height: 100%">
                          <div style="padding-right: 20px;margin-left: 30px;text-align: center;height: 100px;width: 50%">
                            <img :src="solutionBusinessUrl2" style="margin-top: 50px">
                          </div>
                        </el-col>
                        <el-col :md="16" >
                          <div style="width: 100%;margin:50px 20px 20px 0;">
                            <h1 style="font-size: 25px;font-weight: bold;padding: 0">保障信息化基础</h1>
                          </div>
                          <div style="margin-right: 30px;margin-left: 0;margin-top: 20px">
                            <p style="font-size: 12px">
                              综合企业当前信息化建设情况，建立一套统一的信息化集成平台是实现公司战略目标的基础保障，构建统一的人力资源管理（HR门户），
                              项目管理平台（IPM），客户关系管理平台（CRM）界面、数据、流程一体化整合平台。
                            </p>
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </div>
              </el-col>


            </el-row>
            <el-row :gutter="40" style="padding-bottom: 40px">
              <el-col :md="12">
                <div class="grid-content bg-purple-light">
                  <br>
                  <el-row class="myrow">
                    <el-col :md="11"  class="product-solution-mycol">
                      <el-row :gutter="10" style="margin: 0">
                        <el-col :md="8" style="height: 100%">
                          <div style="padding-right: 20px;margin-left: 30px;text-align: center;height: 100px;width: 50%">
                            <img :src="solutionBusinessUrl3" style="margin-top: 50px">
                          </div>
                        </el-col>
                        <el-col :md="16">
                          <div style="width: 100%;margin:50px 20px 20px 0;">
                            <h1 style="font-size: 25px;font-weight: bold;padding: 0">线下管理串联</h1>
                          </div>
                          <div style="margin-right: 20px;margin-left: 0;margin-top: 20px">
                            <p style="font-size: 12px">
                              随着市场快速发展，传统线下管理项目开发方式不利于装备制造业的快速发展，需建立统一标准化的产品开发管理平台，将产品开发全过程串起来。
                            </p>
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
              <el-col :md="12" >
                <div class="grid-content bg-purple-light">
                  <br>
                  <el-row class="myrow">
                    <el-col :md="8"  class="product-solution-mycol">
                      <el-row :gutter="10" style="margin: 0">
                        <el-col :md="8" style="height: 100%">
                          <div style="padding-right: 20px;margin-left: 30px;text-align: center;height: 100px;width: 50%">
                            <img :src="solutionBusinessUrl4" style="margin-top: 50px">
                          </div>
                        </el-col>
                        <el-col :md="16" >
                          <div style="width: 100%;margin:50px 20px 20px 0;">
                            <h1 style="font-size: 25px;font-weight: bold;padding: 0">规范化管理要求</h1>
                          </div>
                          <div style="margin-right: 20px;margin-left: 0;margin-top: 20px">
                            <p style="font-size: 12px">
                              需要将产品开发过程实现集成化、可视化，建立标准化流程，使开发全过程高效开展。
                            </p>
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
            </el-row>
          </div>

          <div style="text-align: center">
            <el-button class="image-button" style="width: 150px;height: 45px;font-size: 18px">获取解决方案
            </el-button>
          </div>
        </b-container>
    </section>
    <section class="partner" style="padding-bottom: 0px;margin-top: 0px">
      <div class="solution-client-backImg">
        <div class="boss-content">
        <b-container fluid="xl" style="margin-bottom: 0px">
          <div style="margin: 0 auto;text-align: center;" class="float-low">
            <br>
            <br>
            <br>
            <br>
            <div class="container">
              <h1 class="solution-title-img">客户价值</h1>
              <br>
              <span style="font-size: 20px;">实现企业门户，打通事业孤岛</span>
            </div>
            <div class="float-solution-all">
              <img :src="successUrl3"/>
            </div>
          </div>
          <div style="margin-top: 300px">
            <el-row :gutter="40">
              <el-col :md="7" :offset="2" >
                <div class="grid-content bg-purple-light">
                  <br>
                  <el-row class="myrow">
                    <el-col :md="5" offset="1" class="product-client-mycol">
                      <el-row :gutter="10" style="margin: 0">
                        <el-col :md="8" style="height: 100%">
                          <div style=";text-align: center;width: 50%;margin-top: 10px;margin-left: 10px">
                            <img :src="customerValue1" style="width: 60px;height: 60px">
                          </div>
                        </el-col>
                        <el-col :md="16">
                          <div style="width: 100%;margin:10px 20px 30px 0;">
                            <h1 style="font-size: 35px;font-weight: bold;padding: 0;color: #3a3a93;margin-bottom: 0px">70%</h1>
                            <p style="font-size: 15px">
                              缩减沟通成本
                            </p>
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
              <el-col :md="7">
                <div class="grid-content bg-purple-light">
                  <br>
                  <el-row class="myrow">
                    <el-col  :md="5" offset="1" class="product-client-mycol">
                      <el-row :gutter="10" style="margin: 0">
                        <el-col :md="8" style="height: 100%">
                          <div style=";text-align: center;width: 50%;margin-top: 10px;margin-left: 10px">
                            <img :src="customerValue2" style="width: 60px;height: 60px">
                          </div>
                        </el-col>
                        <el-col :md="16">
                          <div style="width: 100%;margin:10px 20px 30px 0;">
                            <h1 style="font-size: 35px;font-weight: bold;padding: 0;color: #3a3a93;margin-bottom: 0px">60%</h1>
                            <p style="font-size: 15px">
                              节省人力成本
                            </p>
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
              <el-col :md="7">
                <div class="grid-content bg-purple-light">
                  <br>
                  <el-row class="myrow">
                    <el-col :md="5" :lg="5" offset="1" class="product-client-mycol">
                      <el-row :gutter="10" style="margin: 0">
                        <el-col :md="8" style="height: 100%">
                          <div style=";text-align: center;width: 50%;margin-top: 10px;margin-left: 10px">
                            <img :src="customerValue3" style="width: 60px;height: 60px">
                          </div>
                        </el-col>
                        <el-col :md="16">
                          <div style="width: 100%;margin:10px 20px 30px 0;">
                            <h1 style="font-size: 35px;font-weight: bold;padding: 0;color: #3a3a93;margin-bottom: 0px">30%</h1>
                            <p style="font-size: 15px">
                              业务标准化提升
                            </p>
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </div>
              </el-col>


            </el-row>
            <br>
            <br>
          </div>

        </b-container>
        </div>
      </div>
    </section>

    <section class="partner" style="padding-bottom: 0px;margin-top: 0px">
          <b-container fluid="xl" style="background: white;padding: 0px 0px">
            <div style="text-align: center;margin-top: 50px">
              <h1 class="solution-title-img">
                客户价值
              </h1>
              <div style="position: relative;top: -75px;left:-78px">
                <img :src="successUrl3">
              </div>
            </div>
            <div style="height: 450px;margin-bottom: 50px;box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);" class="custom">
                  <div class="solution-tabHome-header">
                    <div style="padding: 0" v-for="(item, index) in company5" :key="index" class="solution-tabHome" :class="{ active: solutionActiveTab === item.id}" @click="changeSolutionTab(item.id)">
                      <img :src="item.logoPath ? $imgUrl + item.logoPath : ''" style="width: 80%;height: 100%">
                    </div>
                  </div>
                  <br>
                  <div style="margin-top: 100px;height: 500px">
                    <div v-for="(item, index) in company5" :key="index" v-show="solutionActiveTab === item.id"   style="height: 100%;width: 100%;padding-left: 20px;margin-top: 20px;">
                      <el-row :gutter="40">
                        <el-col :xs="4" :sm="6" :md="8" :lg="11" :xl="14">
                          <div>
                            <img :src="item.imagePath? $imgUrl + item.imagePath : ''" style="height: 300px;width: 100%">
                          </div>
                        </el-col>
                        <el-col :xs="4" :sm="6" :md="8" :lg="11" :xl="14"></el-col>
                        <el-col :span="11">
                          <div style="height: 40px;margin-top: 70px">
                            <div style="height: 25px;width: 500px;background: #b1cdfa;margin-top: 12px">
                            </div>
                            <div >
                              <h1 style="font-size: 30px;top: -45px;position: relative;letter-spacing:2px">{{item.ename}}</h1>
                            </div>
                          </div>
                          <div style="height: 130px;">
                            <p>
                                {{item.description}}
                            </p>
                          </div>
                          <div style="width: 100%;">
                            <div v-for="(i, index) in item.tag" :key="index" style="width: 70px;float: left;margin-right: 10px">
                            <el-tag>{{i}}</el-tag>
                            </div>
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                  </div>
                </div>
            <div style="text-align: center;margin-top: 50px;height: 100px">
              <el-button class="image-button" style="width: 150px;height: 45px;font-size: 18px" @click="goMoreExample()">更多案例</el-button>
            </div>
          </b-container>
    </section>
    <section class="partner" style="padding-bottom: 0px;margin-top: 0px">
      <div  class="product-roll-div-image" >
        <div class="boss-content">
        <b-container fluid="xl" style="margin-bottom: 0px">
          <div style="margin: 0 auto;text-align: center;" class="float-low">
            <br>
            <br>
            <br>
            <br>
            <div class="container">
              <h1 style="font-size: 40px;">优质的产品，源于卓越客户的认可</h1>
            </div>
            <div class="float-product-customer">
              <img :src="successUrl3" />
            </div>
          </div>
          <br>
          <br>
          <br>
          <br>
          <br>
        </b-container>
        </div>
        <div style="height: 100%;width:100%;overflow: hidden;">
          <div class="product-carousel">
            <div class="product-carousel-content" :style="{ transform: `translateX(${offset}px)` }">
              <div v-for="(item, index) in carouselItems" :key="index" class="product-carousel-item">
                <div style="background: white;height: 540px;margin-right: 20px">
                  <div style="height: 80px;width: 40%">
                    <img :src="item.logoPath ? $imgUrl + item.logoPath : ''" style="width: 100%;margin-top: 10px;margin-bottom:20px;float: left;height: 80px" >
                  </div>
                  <div style="height: 200px;width: 100%">
                    <img :src="item.imgPath ? $imgUrl + item.imgPath : ''" style="width: 100%;height: 200px" >
                  </div>
                  <div style="height: 180px;width: 100%;margin-top: 10px">
                    <br>
                    <br>
                    <h1 style="float: left;font-size: 25px;margin-left: 25px;">{{item.title}}</h1>
                    <div style="margin-left: 25px;margin-top: 40px;height: 100px">
                      <p style="float: left;">
                        {{item.description}}
                      </p>
                    </div>
                    <el-link type="primary" @click="docs(item)" style="font-size: 20px;float: right;margin-right: 20px">了解更多 ></el-link>
                  </div>
                </div>
                <!--                  <h3 >{{ item }}</h3>-->
              </div>
            </div>
          </div>
          <div style="text-align: center;margin-top: 40px">
            <img :src="buttonLeft" @click="scrollPrev" style="margin-right: 20px">
            <img :src="buttonRight"  @click="scrollNext">
          </div>
        </div>
      </div>
    </section>


    <!--    <section class="partner" style="padding-top: 0px;margin-top: 0px">-->
    <!--    </section>-->
    <!-- <section class="case">
      <b-container fluid="xl">
        <b-row class="mb-1">
          <b-col md="12" class="content">
            <h1>如果您想进一步了解产品，可以联系我们。</h1>
            <h2>
            </h2>
            <button class="liuguang" v-b-modal.modal-prevent-closing>
              演示预约
            </button>
          </b-col>
        </b-row>
      </b-container>
    </section> -->
    <!-- islogan end -->
    <Contact/>
  </div>
</template>

<script>
import Contact from "../components/Contact";
import {WOW} from "wowjs";
import images from "swiper/src/components/core/images";
import solution from "@/views/Solution.vue";

export default {
  name: "Home",
  components: {
    Contact,
  },
  metaInfo: {
    title: "广东壹公里数智科技有限公司",
    meta: [
      {charset: "utf-8"},
      {
        name: "viewport",
        content:
            "width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no",
      },
      {
        vmid: "description",
        name: "description",
        content: "广东壹公里数智科技有限公司官方网站",
      },
      {
        vmid: "keywords",
        name: "keywords",
        content: "物联网应用、智能建筑",
      },
    ],
  },
  data() {
    return {
      company5:[],
      queryInfo: {
        query: "",
        pagenum: 1,
        pagesize: 20,
        total: 0,
      },
      carouselItems: [],
      currentIndex: 0,
      offset: 0,
      solutionActiveTab: '',
      company: {
        name: '',
        companyName: '',
        phone: '',
        address: '',
        need: '',
      },
      activeName: 'second',
      backgroundImageUrl: '../assets/images/home_slices/Group 1754@2x.png',
      fits: ['contain'],

      successUrl3: require("../assets/images/home_slices/Subtract(1).png"),
      solutionTotalUrl: require("../assets/images/solution_slices/平台能力：数据平台技术架构.png"),
      solutionURL1: require("../assets/images/solution_slices/RE_icon_Shoot.png"),
      productURl2: require("../assets/images/productCenter_slices/DI_icon_Model.png"),
      solutionPeculiarityUrl1: require("../assets/images/solution_slices/SP_icon_Info.png"),
      solutionPeculiarityUrl2: require("../assets/images/solution_slices/SP_icon_Safe.png"),
      solutionPeculiarityUrl3: require("../assets/images/solution_slices/SP_icon_Work.png"),
      solutionPeculiarityUrl4: require("../assets/images/solution_slices/DI_icon_Dev.png"),
      solutionBusinessUrl1: require("../assets/images/solution_slices/Group 1952.png"),
      solutionBusinessUrl2: require("../assets/images/solution_slices/Group 1933(1).png"),
      solutionBusinessUrl3: require("../assets/images/solution_slices/Group 1933.png"),
      solutionBusinessUrl4: require("../assets/images/solution_slices/Group 1952(1).png"),
      customerValue1: require("../assets/images/solution_slices/Group 1929.png"),
      customerValue2: require("../assets/images/solution_slices/Group 1931.png"),
      customerValue3: require("../assets/images/solution_slices/Group 1933(2).png"),
      productURl3: require("../assets/images/productCenter_slices/DI_icon_Ops.png"),
      productURl4: require("../assets/images/productCenter_slices/DI_icon_Service .png"),
      upOneUrl2: require("../assets/images/home_slices/RE_icon_Location.png"),
      productDITableURL: require("../assets/images/productCenter_slices/Group 1877.png"),
      fontShallowUrl: require("../assets/images/productCenter_slices/Rectangle 4557@2x.png"),
      buttonLeft: require("../assets/images/productCenter_slices/Group 1905.png"),
      buttonRight: require("../assets/images/productCenter_slices/Group 1906.png"),

      banner: [
        {
          img: require("../assets/images/solution_slices/Group 1949.png"),
        },
      ],
      aboutUs: [
        {
          img: require("../assets/images/download.jpg"),
          path: "/about",
          title: "公司介绍",
          p: `广东壹公里数智科技有限公司,公司成立于1994年，至今已26年。是佛山地区较早专业从事弱电系统集成工程和软件开发服务的高新科技企业之一。自成立以来，公司以"诚信赢得客户、质量。取信客户、服务巩固客户”为宗旨。`,
        },
        {
          img: require("../assets/images/ziji.png"),
          path: "/ziji",
          title: "资质荣誉",
          p: `公司必须清醒地看到前进中的困难与挑战，正视自身存在的差距与不足，以更加坚定的信念、更加饱满的热情、更加务实的作风、
                    更加强大的合，共同谱写公司发展的新篇章，为公司提供强有力的售后服务与支持。`,
        },
      ],
      tabIndex: 0,
      tab: [
        {
          title: "运营服务",
          content: "我们提供全方位的数字化运营服务。",
          img: require("../assets/images/anli/智慧运维系统.jpg"),
        },
        {
          title: "咨询服务",
          content: "我们提供全面的业务咨询服务。",
          img: require("../assets/images/anli/zx1.jpg"),
        },
        {
          title: "集成服务",
          content: "我们提供端到端的系统集成服务。",
          img: require("../assets/images/anli/jc2.jpg"),
        },
      ],
      partner: [],
      anli: [
        {
          name: "智慧运维系统",
          img: require("../assets/images/anli/智慧运维系统.jpg"),
        },
        {
          name: "建筑可视化管理系统",
          img: require("../assets/images/anli/建筑可视化管理系统.jpg"),
        },
        {
          name: "终端安全管理系统",
          img: require("../assets/images/anli/终端安全管理系统.jpg"),
        },
        {
          name: "工业互联网标识数据服务系统",
          img: require("../assets/images/anli/工业互联网标识数据服务系统.png"),
        },
        {
          name: "智慧城市建设",
          img: require("../assets/images/anli/智慧城市建设.png"),
        },
        {
          name: "智能感知安防小区",
          img: require("../assets/images/anli/智能感知安防小区.jpg"),
        },
      ],
      caseList: [
        // {
        //   id: 1,
        //   title: "智慧政务",
        //   description:
        //     "“监管+执法”系列信息系统主要是面向市场监督管理局、城市管理和综合执法局、环保局等监管执法部门，构建移动巡查、移动执法、领导督办等业务应用模块，实现事件上报、在线监测、实时监管、预防犯错、执法有据，为监管执法队伍提供强大便利和有力保障。",
        // },
      ],
      bannerH: 0,
    };
  },
  created() {

  },
  computed: {
    solution() {
      return solution
    },
    images() {
      return images
    },
    backgroundStyle() {
      return {
        'background-image': `require(${this.backgroundImageUrl})`,
        // 其他的背景样式属性也可以在这里添加
      };
    }
  },
  async mounted() {
    // this.getBanners();
    this.getCompanyData();
    //案例获取
    let getArchives = await this.$http.get("/solution");
    if (getArchives.status == 200) {
      let list = getArchives.data;
      if (list.length > 5) {
        list.length = 5;
      }
      this.caseList = list;
    }

    //合作伙伴获取
    let cooperation = await this.$http.get("/cooperation");
    console.log(cooperation);
    if (cooperation.status == 200) {
      this.partner = cooperation.data;
    }
    // 动画重启
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      var wow = new WOW({
        live: false,
      });
      wow.init();
    });
    let w = 600 / 1920;
    let dw = document.documentElement.offsetWidth || document.innerHeight;
    this.bannerH = w * dw;
    console.log("高度", w * dw);
  },
  methods: {
    goMoreExample() {
      this.$router.push('/product/');
    },
    docs(val) {
      this.$router.push("/quarterlyFile/programme/" + val.id);
    },
    async getCompanyData() {
      let _this = this;
      // this.getTitle();
      await _this.$http
          .get(`/AllProduct`, { params: _this.queryInfo })
          .then((e) => {
            console.log(e.data);
            if (e.data.code == 200) {

              this.queryInfo.total = e.data.data.total;
              this.datas = [];
              let tep=e.data.data.data
              for (let index = 0; index < e.data.data.product.length; index++) {
                const element = e.data.data.product[index];
                let tag = element.product.split("、");
                element.tag = tag;
                this.datas.push(element);
              }
              this.carouselItems=e.data.data.product
              this.company5=this.carouselItems.slice(0, 5)
              this.solutionActiveTab=this.company5[0].id
              console.log(this.company5)
            }
          });
      // 动画重启
      this.$nextTick(() => {
        // 在dom渲染完后,再执行动画
        var wow = new WOW({
          live: false,
        });
        wow.init();
      });
    },
    scrollPrev() {
      if (this.currentIndex === 0) {
        this.currentIndex = this.carouselItems.length - 1;
        this.offset = -100 * (this.carouselItems.length - 1);
      } else {
        this.currentIndex--;
        this.offset += 100;
      }
    },
    scrollNext() {
      if (this.currentIndex === this.carouselItems.length - 1) {
        this.currentIndex = 0;
        this.offset = 0;
      } else {
        this.currentIndex++;
        this.offset -= 100;
      }
    },
    changeSolutionTab(tab) {
      this.solutionActiveTab = tab;
      console.log(tab)
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },//家电行业，家居行业等导航栏
    toggleModal() {
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    onSubmit() {
      this.toggleModal();
      alert("我们会第一时间联系你");
      console.log("提交");
    },

    getBanners() {
      this.$http.get("/banner/getBanner")
          .then((response) => {
            // 处理成功情况
            console.log(response.data.data);
            this.banner = response.data.data
          })
          .catch((error) => {
            // 处理错误情况
            console.log(error);
          })
          .then(function () {
            // 总是会执行
          });
    },
  },
};
</script>
<style scoped>
.top-title{
  font-weight: bold;color: white;font-size: 50px
}
.solution-title-img{
  font-size: 40px;font-weight: bold
}
.title-solution{
  width: 100%;
  margin:40px 20px 20px 30px;
}

.image-solution-style{
  height: 130px;
  width: 130px;
}





.float-solution-value {
  position: relative;
  top: -100px;
  left: -75px;
  z-index: 1;
  float: left;
  width: 100%;
}
.solution-tabHome-header {

}
.solution-tabHome {
  cursor: pointer;
  padding: 10px;
  margin-top: 30px;
  border: 2px solid transparent;
  width: 228px;
  height: 60px;
  float: left;
  text-align: center;
  font-size: 20px;
}
.solution-tabHome.active {
  border: 2px solid #409EFF;
}
.solution-tabHome-content-product div {
  display: none;
}
.solution-tabHome-content-product div[style*='display: block'] {
  display: block;
}
.solution-tabHome-content-product {
  position: relative;
  height: 100px;
  width: 100%;
}

.home {
  position: relative;
}
.product-roll-div-image{
  position: relative;
  height: 900px;
  background-image: url('../assets/images/productCenter_slices/Rectangle 4556.png');
}
.product-carousel {
  width: 100%;
  height: 550px;
}

.product-carousel-content {
  width: 400px;
  display: flex;
  transition: transform 0.5s ease-in-out;

}

.product-carousel-item {
  flex: 0 0 100%;
  width: 1000px;
  height: 200px;
  text-align: center;
}

.float-product-customer {
  position: relative;
  top: -75px;
  left: -300px;
  z-index: 1;
  float: left;
  width: 100%;
}
.solution-DI-backImg {
  position: relative;
  background-image: url('../assets/images/productCenter_slices/Rectangle 4557.png');
  height: 1000px;
  width: 100%;
}

.solution-client-backImg {
  position: relative;
  background-image: url('../assets/images/productCenter_slices/Rectangle 4557.png');
  height: 500px;
  width: 100%;
}

.font-backImg {

  position: relative;
  height: 15px; /* 调整容器高度以适应内容 */
  width: 100px;
  background-image: url('../assets/images/productCenter_slices/Rectangle 4557.png');
  top: -10px;
  left: 0;
  z-index: 1;
}

.text {
  z-index: 1;
}

.tab-header {

}

.float-reason-div {
  position: relative;
  top: -100px;
  left: 0;
  z-index: 1;
  float: left;
  width: 100%;
}
.tab {
  cursor: pointer;
  padding: 10px;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  border: 2px solid transparent;
  height: 50px;
  background: #F1F6FF;
  border-radius: 5px;
}

.tab.active {
  border: 2px solid #409EFF;
}

.tab-content-product div {
  display: none;
}

.tab-content-product div[style*='display: block'] {
  display: block;
}

.tab-content-product {
  position: relative;
  height: 100px;
  width: 100%;
}

.b-carousel-indicators {
  position: absolute;
  bottom: 0;
  right: 0;
}

.image-float-style {
  box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);
  margin: 40px 20px 40px 0px;
  background: white;
  height: 80px
}

.product-matrix-style {
  box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);
  margin: 0 20px 20px 0;
  padding: 0 0;
}

.custom.custom-tabs .el-tabs__header {
  margin-right: 29px;
}

.el-header, .el-footer {
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-aside {
  background-color: #D3DCE6;
  color: #333;
  text-align: center;
  line-height: 200px;
}
.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
  line-height: 160px;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

.image-button {
  background: linear-gradient(to right, #2840d4, #399ff7);
  width: 180px;
  height: 50px;
  outline-color: transparent;
  border: none;
}
.image-button:focus{
  box-shadow: none;
  outline-color: transparent;
}

.up-one-title-font {
  font-size: 25px;
  font-weight: bold;
}

.up-one-font {
  font-size: 12px;
  font-weight: bold;
}

.product-solution-mycol {
  height: 200px;
  width: 100%;
}

.product-client-mycol {
  height: 110px;
  width: 100%;
}

.solution-myCol {
  height: 100px;
}

.solution-myrow {
  box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);
  height: 250px;
  width: 100%;
}

.myrow {
  box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);
}

.my-tab-row {
  box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);
  height: 480px;
}

.one-dynamic-h1 {
  font-size: 28px;
  font-weight: bold
}

.tag {

  background: white;
  color: black;
  border-radius: 20px;
  border-width: 1px;
  border-color: black;
  width: 120px;
}

.solution-background-style {
  height: 400px;
  margin-bottom: 0px;
  position: relative;
  padding-bottom: 0;
  background-image: url('../assets/images/productCenter_slices/Rectangle 4556.png');
}

.img-reason-DI {
  height: auto;
  margin-bottom: 0px;
  position: relative;
  background-image: url('../assets/images/productCenter_slices/Rectangle 4556.png');
//background: black;
}

.tag-min {
  background: white;
  color: black;
  border-radius: 20px;
  border-width: 1px;
  border-color: black;
  width: 70px;
}

.item {
  margin-top: 10px;
  margin-right: 40px;
  border-radius: 10px;
}

.container {
  position: relative;
  /* 确保子元素的绝对定位相对于该元素进行定位 */
}

.float-solution-header {
  position: relative;
  top: -400px;
  left: 450px;
  z-index: 1;
  float: left;
  width: 600px;
  margin-top: 30px;
}

.float-font-shallow {
  position: relative;
  top: -26px;
  left: 0px;
  margin-left: 10px;
}

.floating-product-div {
  position: relative;
  top: -240px;
  left: 30px;
  z-index: 1;
  float: left;
  width: 100%;
}

.float-solution-peculiarity-div {
  position: relative;
  top: -60px;
  left: 0;
  z-index: 1;
  margin: 0;
}

.float-DI-div {
  position: relative;
  top: -100px;
  left: 0;
  z-index: 1;
  float: left;
  width: 100%;
}

.float-input-company {
  position: relative;
  top: -500px;
  left: 0px;
  z-index: 1;
  float: left;
  width: 100%;
}

.float-success {
  position: relative;
  top: -40px;
  left: 45px;
  z-index: 1;
  float: left;
  width: 100%;
}


.float-solution-customer {
  position: relative;
  top: -75px;
  left: -85px;
  z-index: 1;
}

.float-solution-div {
  position: relative;
  top: -220px;
  left: 0;
  z-index: 1;
}

.float-DI-title {
  position: relative;
  top: 35px;
  left: -215px;
  z-index: 1;
  width: 100%;
}

.float-customer-product-title {
  position: relative;
  top: -130px;
  left: 0;
  z-index: 1;
  text-align: center;
}

.float-product-due {
  position: relative;
  top: 35px;
  left: -180px;
  z-index: 1;
  float: left;
  width: 100%;
}

.title-logo {
  font-size: 40px;
  font-weight: bold
}

.float-contact {
  position: relative;
  top: -300px;
  left: 0px;
  z-index: 1;
  float: left;
  width: 100%;
}

.float-low {
  position: relative;
//top: -100px; left: 0px; z-index: 1;
  width: 100%;
  text-align: center;
//background: black;
}

.float-solution-peculiarity {
  position: relative;
//top: -100px; left: 0px; z-index: 1;
  left: -85px;
  top: -78px;
  width: 100%;
  margin-top: 0;
  text-align: center;
//background: black;
}

.float-solution-business {
  position: relative;
//top: -100px; left: 0px; z-index: 1;
  left: -85px;
  top: -155px;
  width: 100%;
  margin-top: 0;
  text-align: center;
//background: black;
}

.float-div {
  position: relative;
  top: -400px;
  left: 45px;
  z-index: 1;
  float: left;
  width: 100%;
}

.float-LONDOD {
  position: relative;
  top: -200px;
  left: -56px;
  z-index: 1;
  float: left;
  width: 100%
}

.float-div-WABON {
  position: relative;

  top: -400px;
  left: 0px;
  z-index: 2;
  float: left;
  width: 100%;
}

.cover-ALL-industry {
  width: 100%;
  text-align: center;
}

.float-title {
  position: relative;
  top: -80px;
  left: -260px;
  z-index: 2;
  float: left;
  width: 100%;
}

.float-div-inner {
  position: relative;
  top: -200px;
  left: 0px;
  z-index: 2;
  float: left;
  width: 100%;
}

.float-image-title {
  position: relative;
  top: 0;
  left: 0px;
  z-index: 2;
  //float: left;
  width: 100%;
}

.quote {
  width: 200px;
  height: 200px;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: white;
}

.bg-purple {
  background: white;
}

.bg-purple-light {
  background: white;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: white;
}
</style>
<style lang="scss" scoped>
.carousel-item {
  // height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 100%;
  }
}

@media (min-width: 1620px) {
  .container {
    max-width: 1620px;
  }
}

.textColor {
  position: relative;

  &::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    color: transparent;
    background-image: linear-gradient(
            90deg,
            #03a9f4,
            #ffeb3b,
            #f441a5,
            #03a9f4
    );
    background-clip: text;
    clip-path: ellipse(50px 50px at 0% 50%);
    animation: spotlight 2s infinite;
  }
}

@keyframes spotlight {
  0% {
    clip-path: ellipse(50px 50px at 0% 50%);
  }
  50% {
    clip-path: ellipse(50px 50px at 100% 50%);
  }
  100% {
    clip-path: ellipse(50px 50px at 0% 50%);
  }
}

.h1 {
  text-align: center;
  // color: #fff;
  font-size: 2rem;
  font-weight: 900;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.flex-centen {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mode {
  position: relative;
  overflow: hidden;

  img {
    height: 170px;
    width: 100%;
    display: block;
  }

  .description {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: -100%;
    left: 0;
    border-radius: 4px;
    padding: 22px 10px;
    padding: 20px;
    background-color: rgba($color: #000, $alpha: 0.5);
    transition: all 1s;
    color: #fff;
    font-size: 1rem;
  }

  &:hover {
    .description {
      bottom: 0;
    }
  }

  &.last {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    border: 1px solid;
    color: #409eff;
  }
}

.flexcenten {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

// .img-fluid{
//   max-width: 100%;
//   height: calc(100% - 1rem);
// }
</style>