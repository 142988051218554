import { render, staticRenderFns } from "./FoodstuffSolution.vue?vue&type=template&id=0388d7b4&scoped=true&"
import script from "./FoodstuffSolution.vue?vue&type=script&lang=js&"
export * from "./FoodstuffSolution.vue?vue&type=script&lang=js&"
import style0 from "./FoodstuffSolution.vue?vue&type=style&index=0&id=0388d7b4&scoped=true&lang=css&"
import style1 from "./FoodstuffSolution.vue?vue&type=style&index=1&id=0388d7b4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0388d7b4",
  null
  
)

export default component.exports