<template>
  <div class="home">
    <b-carousel
      id="carousel-fade"
      style="text-shadow: 0px 0px 2px #000"
      fade
      indicators
    >
      <b-carousel-slide
        v-for="item in banner"
        :key="item.img"
        :img-src="item.img"
      ></b-carousel-slide>
    </b-carousel>

    <!-- <div class="ibanner"> -->
    <!-- <img
        src="../assets/images/banner.png"
        class="img animated fadeInDown"
        alt=""
      /> -->

    <!-- <b-container fluid="xl" class="flexcenten">
        <b-row style="width: 100%">
          <b-col md="6" class="">
          </b-col>
          <b-col md="6">
            <div class="ibanner_box">
              <ul>
                <li>
                  <h1 class="animated bounceInDown" data-text="广东壹公里">
                    广东壹公里
                  </h1>
                </li>
                <li class="animated bounceInLeft">
                  政务服务软件开发<br />
                  专业建筑智能化系统集成商<br />
                  信息网络安全服务商
                </li>
                <li><h1 class="animated bounceInUp">拓展超越</h1></li>
                <li
                  style="text-align: right; font-weight: 800"
                  class="animated bounceInRight"
                >
                  中国·佛山
                </li>
              </ul>
            </div>
          </b-col>
        </b-row>
      </b-container> -->
    <!-- </div> -->
    <!-- ibanner end -->
    <!-- 关于我们 -->
    <section class="ielit">
      <b-container fluid="xl">
        <h1>关于我们</h1>
        <small>
          <b-row class="mb-4" align-h="center">
            <b-col
              md="6"
              class="wow animated fadeInLeft"
              v-for="(item, i) in aboutUs"
              :key="i"
            >
              <router-link :to="item.path" class="boxBotron">
                <img :src="item.img" fluid :alt="item.title"/>
                <div class="boxBotron_content">
                  <h6
                    class="boxBotron_content_title font-24 font-weight"
                    style="font-weight: bold"
                  >
                    {{ item.title }}
                  </h6>
                  <p class="boxBotron_description font-14 md-12">
                    {{ item.p }}
                  </p>
                </div>
              </router-link>
            </b-col>
          </b-row>
        </small>
      </b-container>
    </section>
    <!-- 专业服务 -->
    <section class="service">
      <h1 style="font-weight: bold">专业服务</h1>
      <b-container fluid="xl">
        <b-row class="mb-3 tab xs-0">
          <b-col md="4" class="tablist">
            <ul>
              <li
                v-for="(item, i) in tab"
                :key="i"
                @mouseover="tabIndex = i"
                :class="i == tabIndex ? 'active' : ''"
              >
                <dl>
                  <dt>
                    <b-icon icon="chevron-right"></b-icon>
                  </dt>
                  <dd>
                    <h6 class="font-24 text-dark">{{ item.title }}</h6>
                    <p class="font-14">{{ item.content }}</p>
                  </dd>
                </dl>
              </li>
            </ul>
          </b-col>
          <b-col md="8" class="imgShow">
            <img
              v-for="(item, ind) in tab"
              :key="ind"
              :src="item.img"
              :class="
                ind == tabIndex ? 'active animated fadeIn' : 'animated fadeIn'
              "
            />
          </b-col>
        </b-row>
        <b-row class="mb-4 xl-0">
          <b-col md="4" v-for="item in tab" :key="item.title">
            <b-card
              :title="item.title"
              :img-src="item.img"
              :img-alt="item.title"
              img-top
              class="mb-2"
            >
              <b-card-text>
                {{ item.title }}<br />
                {{ item.content }}
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <!-- 应用方案 -->
    <b-container fluid="xl">
      <h1 class="h1">解决方案</h1>
      <b-row class="mb-3">
        <b-col md="4" v-for="(item, i) in caseList" :key="item.id">
          <router-link
            class="hover mode wow animated fadeInLeft"
            :data-wow-delay="i / 8 + 's'"
            :to="{ path: '/quarterlyFile/programme/' + item.id }"
          >
            <div class="img">
              <img
                :src="item.imagePath ? $imgUrl + item.imagePath : ''"
                fluid
                :alt="item.title"
              />
            </div>
            <h2>{{ item.title }}</h2>
            <div class="description">
              {{ item.description }}
            </div>
          </router-link>
        </b-col>
        <b-col md="4">
          <router-link
            to="/case"
            class="last hover mode wow animated fadeInLeft"
            :data-wow-delay="caseList.length / 8 + 's'"
          >
            更多...
          </router-link>
        </b-col>
      </b-row>
    </b-container>
    <!-- 合作伙伴 -->
    <section class="partner">
      <h1 class="h1">合作伙伴</h1>
      <b-container fluid="xl">
        <b-row class="mb-4" cols="3" cols-sm="3" cols-md="4" cols-lg="6">
          <b-col
            md="6"
            v-for="(item, i) in partner"
            :key="i"
            :data-wow-delay="i / 20 + 's'"
            class="wow animated fadeInLeft"
            style="margin-bottom: 20px"
          >
            <div class="partnerItem boxShadow">
              <img
                :src="item.imagePath ? $imgUrl + item.imagePath : ''"
                fluid
                :alt="item.title"
              />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <!-- <section class="case">
      <b-container fluid="xl">
        <b-row class="mb-1">
          <b-col md="12" class="content">
            <h1>如果您想进一步了解产品，可以联系我们。</h1>
            <h2>
            </h2>
            <button class="liuguang" v-b-modal.modal-prevent-closing>
              演示预约
            </button>
          </b-col>
        </b-row>
      </b-container>
    </section> -->
    <!-- islogan end -->
    <Contact />
  </div>
</template>

<script>
import Contact from "../components/Contact";
// import Counseling from "../views/Counseling.vue";
import { WOW } from "wowjs";
export default {
  name: "Home",
  components: {
    Contact,
    // Counseling,
  },
  metaInfo: {
    title: "广东壹公里数智科技有限公司",
    meta: [
      { charset: "utf-8" },
      {
        name: "viewport",
        content:
          "width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no",
      },
      {
        vmid: "description",
        name: "description",
        content: "广东壹公里数智科技有限公司官方网站",
      },
      {
        vmid: "keywords",
        name: "keywords",
        content: "物联网应用、智能建筑",
      },
    ],
  },
  data() {
    return {
      banner: [
        {
          img: require("../assets/images/banner2.jpg"),
        },
        {
          img: require("../assets/images/banner1.jpg"),
        },
      ],
      aboutUs: [
        {
          img: require("../assets/images/download.jpg"),
          path: "/about",
          title: "公司介绍",
          p: `广东壹公里数智科技有限公司,公司成立于1994年，至今已26年。是佛山地区较早专业从事弱电系统集成工程和软件开发服务的高新科技企业之一。自成立以来，公司以"诚信赢得客户、质量。取信客户、服务巩固客户”为宗旨。`,
        },
        {
          img: require("../assets/images/ziji.png"),
          path: "/ziji",
          title: "资质荣誉",
          p: `公司必须清醒地看到前进中的困难与挑战，正视自身存在的差距与不足，以更加坚定的信念、更加饱满的热情、更加务实的作风、
                    更加强大的合，共同谱写公司发展的新篇章，为公司提供强有力的售后服务与支持。`,
        },
      ],
      tabIndex: 0,
      tab: [
        {
          title: "运营服务",
          content: "我们提供全方位的数字化运营服务。",
          img: require("../assets/images/anli/智慧运维系统.jpg"),
        },
        {
          title: "咨询服务",
          content: "我们提供全面的业务咨询服务。",
          img: require("../assets/images/anli/zx1.jpg"),
        },
        {
          title: "集成服务",
          content: "我们提供端到端的系统集成服务。",
          img: require("../assets/images/anli/jc2.jpg"),
        },
      ],
      partner: [],
      anli: [
        {
          name: "智慧运维系统",
          img: require("../assets/images/anli/智慧运维系统.jpg"),
        },
        {
          name: "建筑可视化管理系统",
          img: require("../assets/images/anli/建筑可视化管理系统.jpg"),
        },
        {
          name: "终端安全管理系统",
          img: require("../assets/images/anli/终端安全管理系统.jpg"),
        },
        {
          name: "工业互联网标识数据服务系统",
          img: require("../assets/images/anli/工业互联网标识数据服务系统.png"),
        },
        {
          name: "智慧城市建设",
          img: require("../assets/images/anli/智慧城市建设.png"),
        },
        {
          name: "智能感知安防小区",
          img: require("../assets/images/anli/智能感知安防小区.jpg"),
        },
      ],
      caseList: [
        // {
        //   id: 1,
        //   title: "智慧政务",
        //   description:
        //     "“监管+执法”系列信息系统主要是面向市场监督管理局、城市管理和综合执法局、环保局等监管执法部门，构建移动巡查、移动执法、领导督办等业务应用模块，实现事件上报、在线监测、实时监管、预防犯错、执法有据，为监管执法队伍提供强大便利和有力保障。",
        // },
      ],
      bannerH: 0,
    };
  },
  created() {},
  async mounted() {
    //案例获取
    let getArchives = await this.$http.get("/solution");
    if (getArchives.status == 200) {
      let list = getArchives.data;
      if (list.length > 5) {
        list.length = 5;
      }
      this.caseList = list;
    }

    //合作伙伴获取
    let cooperation = await this.$http.get("/cooperation");
    console.log(cooperation);
    if (cooperation.status == 200) {
      this.partner = cooperation.data;
    }
    // 动画重启
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      var wow = new WOW({
        live: false,
      });
      wow.init();
    });
    let w = 600 / 1920;
    let dw = document.documentElement.offsetWidth || document.innerHeight;
    this.bannerH = w * dw;
    console.log("高度", w * dw);
  },
  methods: {
    toggleModal() {
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    onSubmit() {
      this.toggleModal();
      alert("我们会第一时间联系你");
      console.log("提交");
    },
  },
};
</script>
<style lang="scss" scoped>
.carousel-item {
  // height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 100%;
  }
}
@media (min-width: 1620px) {
  .container {
    max-width: 1620px;
  }
}
.textColor {
  position: relative;
  &::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    color: transparent;
    background-image: linear-gradient(
      90deg,
      #03a9f4,
      #ffeb3b,
      #f441a5,
      #03a9f4
    );
    background-clip: text;
    clip-path: ellipse(50px 50px at 0% 50%);
    animation: spotlight 2s infinite;
  }
}
@keyframes spotlight {
  0% {
    clip-path: ellipse(50px 50px at 0% 50%);
  }
  50% {
    clip-path: ellipse(50px 50px at 100% 50%);
  }
  100% {
    clip-path: ellipse(50px 50px at 0% 50%);
  }
}

.h1 {
  text-align: center;
  // color: #fff;
  font-size: 2rem;
  font-weight: 900;
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.flex-centen {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mode {
  position: relative;
  overflow: hidden;
  img {
    height: 170px;
    width: 100%;
    display: block;
  }
  .description {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: -100%;
    left: 0;
    border-radius: 4px;
    padding: 22px 10px;
    padding: 20px;
    background-color: rgba($color: #000, $alpha: 0.5);
    transition: all 1s;
    color: #fff;
    font-size: 1rem;
  }
  &:hover {
    .description {
      bottom: 0;
    }
  }
  &.last {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    border: 1px solid;
    color: #409eff;
  }
}

.flexcenten {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
// .img-fluid{
//   max-width: 100%;
//   height: calc(100% - 1rem);
// }
</style>