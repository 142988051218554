<template>
  <div class="kefu">
    <ul>
      <li>
        <a :href="'tel:' + tel"
          ><span>联系我们</span><i class="zi zi_phone zi_rotate90"></i
        ></a>
      </li>
      <li class="kefu_wechat">
        <a href="javascript:;"
          ><span
            ><img src="../assets/images/weixin.jpg" alt="微信二维码" /></span
          ><i class="zi zi_tmWeixin" zico="微信"></i
        ></a>
      </li>
      <li>
        <a href="/Guest" target="_blank"
          ><span>留言咨询</span><i class="zi zi_msgchat"></i
        ></a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ZoomlaKefu",
  props: {
    tel: {
      type:String,
      default:"0757-88384262"
    },
    // icpOpen: Boolean,
    // icp:String,
    // fff:Number
  },
  data() {
    return {};
  },
  components: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang='scss' scoped>
</style>
// 使用说明
// 二维码图片必须在这指定路径