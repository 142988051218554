import axios from "axios"
import Vue from "vue"

const http = axios.create({
    baseURL: process.env.VUE_APP_API_URL || '/'
})

// 响应拦截器
http.interceptors.response.use(res=>{
    return res
},err=>{
    console.log(err, '')
    if (err){
        console.log("连接超时")
    }
    return Promise.reject(err);
})
export default http