<template>
  <b-modal
    id="modal-prevent-closing"
    ref="modal"
    title="留下联系方式、我们联系你"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
        :state="nameState"
        label="姓名"
        label-for="name-input"
        invalid-feedback="请输入名字"
      >
        <b-form-input
          id="name-input"
          v-model="form.name"
          required
          placeholder="你的称呼"
          :state="nameState"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :state="nameState"
        label="联系电话"
        label-for="name-input"
        invalid-feedback="请输入你的联系电话，我们会第一时间联系你"
      >
        <b-form-input
          id="name-input"
          v-model="form.telephone"
          required
          type="number"
          placeholder="联系方式"
          :state="nameState"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="留言" label-for="name-input">
        <b-form-textarea
          v-model="form.content"
          placeholder="有什么对我们说的"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </b-form-group>
    </form>
    <template #modal-footer="{ ok }">
      <b-button size="sm" variant="success" @click="ok()"> 提交 </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {},
  data() {
    return {
      nameState: null,
      form: {
        name: "",
        telephone: "",
        content: "",
      },
    };
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.name = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    async handleSubmit() {
      var ref = this;
      if (ref.Regex.isEmpty(ref.form.name)) {
        alert("姓名不能为空");
        return false;
      }
      if (!ref.Regex.isMobilePhone(ref.form.telephone)) {
        alert("手机号格式不正确 ");
        return false;
      }
      console.log("提交");
      await this.$http.post("/message", ref.form).then((e) => {
        if (e.status == 200) {
          alert("我们会第一时间联系你");
          this.$nextTick(() => {
            this.$bvModal.hide("modal-prevent-closing");
          });
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.modal-footer{
  width: 100%;
}
</style>
