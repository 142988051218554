<template>
  <div class="home">
    <div style="position: relative">
      <div class="carousel-container">
        <!-- 这里是内容 -->
        <b-carousel
            id="carousel-fade"
            style="text-shadow: 0px 0px 2px #000"
            fade
            indicators:none
        >
          <b-carousel-slide
              v-for="item in banner"
              :key="item.id"
              :img-src="item.img"
          ></b-carousel-slide>
          <div class="boss-content" style="z-index: 9;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;">
            <div style="position: relative;left: 58px">
              <div class="boss-content">
                <h1 style="font-weight: bold;font-size: 50px" ><span style="color: #153869;font-weight: bold">BossDI</span>数据中台</h1>
                    <span style="font-size: 20px">阿里云选择的数据中台研发合作伙伴</span>
                  <br>
                  <br>
                  <el-button class="image-button"  @click="tryUse">立即试用<img :src="arrowhead" style="margin-left: 30px;height: 15px;margin-bottom: 3px"></el-button>
              </div>
            </div>
          </div>
        </b-carousel>
      </div>
    </div>
    <section class="partner" style="padding-bottom: 50px;margin-top: 170px;margin-bottom: 0;">
      <div>
        <div class="boss-content" style="padding-bottom: 100px">
        <b-container fluid="xl" style="margin-bottom: 0;">
          <div style="text-align: center;position: relative">
            <div style="position: relative;top: -115px;left: -315px">
              <img :src="successUrl3">
            </div>
          <div style="top: -150px;position: relative">
           <h1 class="solution-title-img">为什么选择壹公里<span style="color: #153869">BossDI</span>数据中台?</h1>
          </div>
          </div>
        </b-container>
        <b-container fluid="xl" style="margin-bottom: 0;">
            <el-row :gutter="40" >
              <el-col  :md="6" >
                <div class="grid-content bg-purple-light">
                  <el-row class="myrow">
                    <el-col :md="11"  offset="1" class="product-mycol">
                      <div style="padding-right: 20px;text-align: center;height: 100px" class="float-reason-div">
                      <img :src="productURl1">
                      </div>
                      <div style="text-align: center;margin-right: 20px">
                        <h1 style="font-size: 20px;font-weight: bold">低门槛数据开发</h1>
                      </div>
                      <div style="margin-right: 20px;margin-left: 20px;margin-top: 20px">
                        <p style="font-size: 15px">壹公里的数据中台为企业采集全域数据，存储异构数据。以毫秒级响应离线/实时数据同步，同时以在线作业编辑方式，减低开发门槛。</p>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
              <el-col :md="6">
                <div class="grid-content bg-purple-light">
                  <el-row class="myrow">
                    <el-col :xs="4" :sm="6" :md="12" :lg="5" :xl="11" offset="1" class="product-mycol">
                      <div style="padding-right: 20px;text-align: center;height: 100px" class="float-reason-div">
                      <img :src="productURl2">
                      </div>
                      <div style="text-align: center;margin-right: 20px">
                        <h1 style="font-size: 20px;font-weight: bold">可视化数据建模</h1>
                      </div>
                      <div style="margin-right: 20px;margin-left: 20px;margin-top: 20px">
                        <p style="font-size: 15px">壹公里数据中台具备可视化维度建模能力，基于实体数据模型建立指标体系，消除二义性，搭建标签类目体系及标签实体画像。</p>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
              <el-col :md="6">
                <div class="grid-content bg-purple-light">
                  <el-row class="myrow">
                    <el-col :xs="4" :sm="6" :md="12" :lg="5" :xl="11" offset="1" class="product-mycol">
                      <div style="padding-right: 20px;text-align: center;height: 100px" class="float-reason-div">
                      <img :src="productURl3">
                      </div>
                      <div style="text-align: center;margin-right: 20px">
                        <h1 style="font-size: 20px;font-weight: bold">全局数据运维监控</h1>
                      </div>
                      <div style="margin-right: 20px;margin-left: 20px;margin-top: 20px">
                        <p style="font-size: 15px">壹公里数据中台可以实现企业全局运维监控，运行时段分析，失败告警，基线设置方式，及时发出失败告警，实现自动补充数据，补下游。</p>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
              <el-col :md="6">
                <div class="grid-content bg-purple-light">
                  <el-row class="myrow">
                    <el-col :xs="4" :sm="6" :md="12" :lg="5" :xl="11" offset="1" class="product-mycol">
                      <div style="padding-right: 20px;text-align: center;height: 100px" class="float-reason-div">
                      <img :src="productURl4">
                      </div>
                      <div style="text-align: center;margin-right: 20px">
                        <h1 style="font-size: 20px;font-weight: bold">全局数据运维监控</h1>
                      </div>
                      <div style="margin-right: 20px;margin-left: 20px;margin-top: 20px">
                        <p style="font-size: 15px">壹公里数据中台可以为企业快速配置生成API，面向业务提供API调用及监控，搭建安全的数据服务生态。</p>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
            </el-row>
        </b-container>
        </div>
      </div>
    </section>
    <section class="partner" >
      <div class="img-reason-DI">
        <div class="boss-content">
        <b-container fluid="xl" style="margin-bottom: 0px">
          <div class="float-low">
            <div class="float-DI-title">
              <img :src="successUrl3"/>
            </div>
            <div class="container">
              <h1 class="title-logo">壹公里<span style="color: #153869">BossDI</span>数据中台</h1>
            </div>
            <div style="margin-top: 20px">
              <p style="font-size: 20px">通过数据技术，对海量数据进行采集、计算、存储、加工。同时统一标准、同时形成统一的标准、形成标准数据、进行存储，做为数据资产，为企业提供高效服务。这些数据与企业业务有强关联型，是企业独有、且可复用的资产。
                得帆数据中台有效降低数据子啊从的重复建设和协作成本，为企业建立差异化竞争优势。
              </p>
            </div>
            <br>
            <br>

            <el-row>
              <div style="width: 100%;height: auto">
              <img :src="productDITableURL" style="width: 100%;height: 100%">
              </div>
            </el-row>
            <br>
          </div>
        </b-container>
        </div>
      </div>
    </section>
    <section class="partner" style="padding-bottom: 0px;padding-top: 80px">
      <div class="boss-content">
        <b-container fluid="xl" style="padding-top: 0">
          <div style="margin: 0 auto;text-align: center;padding-bottom: 50px" class="float-low">
            <div class="float-product-due">
              <img :src="successUrl3"/>
            </div>
            <div class="container">
              <h1 class="title-logo">数字化从未如此简单</h1>
            </div>
          </div>
          <div style="padding-bottom: 50px">
            <el-row :gutter="40">
              <el-col :lg="6"  >
                <div class="grid-content bg-purple-light">
                  <el-row class="my-tab-row">
                    <div class="tab-header">
                      <div class="tab" :class="{ active: activeTab === 'first' }" @click="changeTab('first')">开发作业</div>
                      <div class="tab" :class="{ active: activeTab === 'second' }" @click="changeTab('second')">标签开发</div>
                      <div class="tab" :class="{ active: activeTab === 'third' }" @click="changeTab('third')">数据质量</div>
                      <div class="tab" :class="{ active: activeTab === 'fourth' }" @click="changeTab('fourth')">数据服务</div>
                      <div class="tab" :class="{ active: activeTab === 'five' }" @click="changeTab('five')">数据资产</div>
                      <div class="tab" :class="{ active: activeTab === 'six' }" @click="changeTab('six')">运维监控</div>
                    </div>
                    <div style="margin-top: 50px;margin-left: 20px">
                    <el-button class="image-button-min" style="width: 120px" @click="consultation()" >&nbsp;&nbsp;立即咨询 &nbsp;&nbsp; </el-button>
                    </div>
                  </el-row>
                </div>
              </el-col>
              <el-col :md="17">
                  <el-row class="my-tab-row">
                      <div  v-show="activeTab === 'first'"  style="height: 100%;padding-left: 20px;margin-top: 0">
                          <div style="margin-top: 10px;margin-left: 10px"><img :src="fontShallowUrl" style="height: 12px;width: 110px;z-index: 0" ></div>
                          <div class="float-font-shallow" >
                            <h1 style="font-size: 22px;font-weight: bolder">开发作业</h1>
                            <p style="font-size: 13px">开发作业，是平台的最小运行单元，目前支持，Hive、Spark、SparkSQL、DDL等多种作业类型。作业包含离线作业、实时作业和临时作业，其中临时作业
                            是一种特殊的作业，用于一次性查询或分析数据。
                            </p>
                          </div>
                        <div class="imgBackground-product">
                          <div>
                            <img :src="homeworkUrl" style="width: 600px;height: 300px;margin:10px 10px 20px 10px;">
                          </div>
                        </div>
                        </div>
                      <div v-show="activeTab === 'second'"  style="height: 100%;padding-left: 20px;margin-top: 20px">
                        <div style="margin-top: 30px;margin-left: 10px"><img :src="fontShallowUrl" style="height: 12px;width: 110px;z-index: 0" ></div>
                        <div class="float-font-shallow" >
                          <h1 style="font-size: 22px;font-weight: bolder">标签开发</h1>
                        </div>
                      </div>
                      <div v-show="activeTab === 'third'"   style="height: 100%;padding-left: 20px;margin-top: 20px">
                        <div style="margin-top: 30px;margin-left: 10px"><img :src="fontShallowUrl" style="height: 12px;width: 110px;z-index: 0" ></div>
                        <div class="float-font-shallow" >
                          <h1 style="font-size: 22px;font-weight: bolder">数据质量</h1>
                        </div>
                      </div>
                      <div v-show="activeTab === 'fourth'"  style="height: 100%;padding-left: 20px;margin-top: 20px">
                        <div style="margin-top: 30px;margin-left: 10px"><img :src="fontShallowUrl" style="height: 12px;width: 110px;z-index: 0" ></div>
                        <div class="float-font-shallow" >
                          <h1 style="font-size: 22px;font-weight: bolder">数据服务</h1>
                        </div>
                        </div>
                      <div v-show="activeTab === 'five'"  style="height: 100%;padding-left: 20px;margin-top: 20px">
                        <div style="margin-top: 30px;margin-left: 10px"><img :src="fontShallowUrl" style="height: 12px;width: 110px;z-index: 0" ></div>
                        <div class="float-font-shallow" >
                          <h1 style="font-size: 22px;font-weight: bolder">数据资产</h1>
                        </div>
                      </div>
                      <div v-show="activeTab === 'six'"  style="height: 100%;padding-left: 20px;margin-top: 20px">
                        <div style="margin-top: 30px;margin-left: 10px"><img :src="fontShallowUrl" style="height: 12px;width: 110px;z-index: 0" ></div>
                        <div class="float-font-shallow" >
                          <h1 style="font-size: 22px;font-weight: bolder">运维监控</h1>
                        </div>
                        </div>
                  </el-row>

              </el-col>
            </el-row>
          </div>

        </b-container>
      </div>
    </section>
    <section class="partner" style="padding-bottom: 0px;margin-top: 0px">
      <div  class="product-roll-div-image" >
        <b-container fluid="xl" style="margin-bottom: 0px">
          <div style="margin: 0 auto;text-align: center;" class="float-low">
            <br>
            <br>
            <br>
            <br>
            <div class="container">
              <h1 style="font-size: 40px;font-weight: bold">优质的产品，源于卓越客户的认可</h1>
            </div>
            <div class="float-product-customer">
              <img :src="successUrl3" />
            </div>
          </div>
          <br>
          <br>
          <br>
          <br>
          <br>
        </b-container>
          <div style="width: 100%;overflow: hidden">
          <div class="product-carousel">
            <div class="product-carousel-content" :style="{ transform: `translateX(${offset}px)` }">
              <div v-for="(item, index) in carouselItems" :key="index" class="product-carousel-item">
                <div style="background: white;height: 540px;margin-right: 20px">
                  <div style="height: 80px;width: 40%">
                    <img :src="item.logoPath? $imgUrl + item.logoPath : ''" style="width: 100%;margin-top: 10px;margin-bottom:20px;float: left;height: 80px" >
                  </div>
                  <div style="height: 200px;width: 100%">
                    <img :src="item.imgPath ? $imgUrl + item.imgPath : ''" style="width: 100%;height: 200px" >
                  </div>
                  <div style="height: 180px;width: 100%;margin-top: 10px">
                    <br>
                    <br>
                    <h1 style="float: left;font-size: 25px;margin-left: 25px;">{{item.ename}}</h1>
                    <div style="margin-left: 25px;margin-top: 40px;height: 100px">
                    <p style="float: left;">
                      {{item.description}}
                    </p>
                    </div>
                    <el-link type="primary" @click="docs(item)" style="font-size: 20px;float: right;margin-right: 20px">了解更多 ></el-link>
                  </div>
                </div>
                <!--                  <h3 >{{ item }}</h3>-->
              </div>
            </div>
          </div>
            <div style="text-align: center;margin-top: 40px">
              <img :src="buttonLeft" style="margin-right: 20px" v-show="LeftFlag">
              <img :src="buttonLeftBlue" @click="scrollPrev" style="margin-right: 20px"  v-show="toLeftFlag">
              <img :src="buttonRightBlue"  @click="scrollNext" v-show="toRightFlag">
              <img :src="buttonRight" v-show="RightFlag">
            </div>
        </div>
      </div>
    </section>
    <Contact/>
  </div>
</template>

<script>
import Contact from "../components/Contact";
import {WOW} from "wowjs";
import images from "swiper/src/components/core/images";

export default {
  name: "Home",
  components: {
    Contact,
  },
  metaInfo: {
    title: "广东壹公里数智科技有限公司",
    meta: [
      {charset: "utf-8"},
      {
        name: "viewport",
        content:
            "width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no",
      },
      {
        vmid: "description",
        name: "description",
        content: "广东壹公里数智科技有限公司官方网站",
      },
      {
        vmid: "keywords",
        name: "keywords",
        content: "物联网应用、智能建筑",
      },
    ],
  },
  data() {
    return {
      toLeftFlag:false,
      toRightFlag:true,
      LeftFlag:true,
      RightFlag:false,
      queryInfo: {
        query: "",
        pagenum: 1,
        pagesize: 20,
        total: 0,
      },
      slides: [
        {
          src: require("../assets/images/home_slices/PD_icon_Jicheng.png"),
          alt: '图片1'
        },
        {
          src: require("../assets/images/home_slices/PD_icon_Jicheng.png"),
          alt: '图片1'
        },
        {
          src: require("../assets/images/home_slices/PD_icon_Jicheng.png"),
          alt: '图片1'
        },
        {
          src: require("../assets/images/home_slices/PD_icon_Jicheng.png"),
          alt: '图片1'
        },
        // { src: 'image2.jpg', alt: '图片2' },
        // { src: 'image3.jpg', alt: '图片3' }
      ],
      currentSlide: 0,
      activeTab: 'first',
      company: {
        name: '',
        companyName: '',
        phone: '',
        address: '',
        need: '',
      },
      activeName: 'second',
      backgroundImageUrl: '../assets/images/home_slices/Group 1754@2x.png',
      fits: ['contain'],
      url: require("../assets/images/home_slices/point_01.png"),
      url1: require("../assets/images/home_slices/point_02.png"),
      url2: require("../assets/images/home_slices/point_03.png"),
      url3: require("../assets/images/home_slices/point_04.png"),
      url4: require("../assets/images/home_slices/point_05.png"),
      productUrl: require("../assets/images/home_slices/PD_icon_BossDI 1.png"),
      productUrl1: require("../assets/images/home_slices/PD_icon_Jicheng.png"),
      productUrl2: require("../assets/images/home_slices/PD_icon_BossBI 1.png"),
      productUrl3: require("../assets/images/home_slices/PD_icon_BossMa2 1.png"),
      productUrl4: require("../assets/images/home_slices/PD_icon_Menhu 1.png"),
      productUrl5: require("../assets/images/home_slices/PD_icon_IoT 1.png"),
      successUrl1: require("../assets/images/home_slices/lianbangbg.png"),
      successUrl2: require("../assets/images/home_slices/Group 1781.png"),
      successUrl3: require("../assets/images/home_slices/Subtract(1).png"),
      successUrl4: require("../assets/images/home_slices/Rectangle 30@2x.png"),
      successUrl5: require("../assets/images/home_slices/LANDBOND_Logo 1.png"),
      successUrl6: require("../assets/images/home_slices/万和新logo组合（让家更温暖） 1.png"),
      companyUrl: require("../assets/images/home_slices/Group 1806.png"),
      companyContactUrl: require("../assets/images/home_slices/Group 1781(1).png"),
      productURl1: require("../assets/images/productCenter_slices/DI_icon_Dev.png"),
      productURl2: require("../assets/images/productCenter_slices/DI_icon_Model.png"),
      productURl3: require("../assets/images/productCenter_slices/DI_icon_Ops.png"),
      productURl4: require("../assets/images/productCenter_slices/DI_icon_Service .png"),
      upOneUrl2: require("../assets/images/home_slices/RE_icon_Location.png"),
      productDITableURL: require("../assets/images/productCenter_slices/Group 1877.png"),
      fontShallowUrl: require("../assets/images/productCenter_slices/Rectangle 4557@2x.png"),
      homeworkUrl: require("../assets/images/productCenter_slices/developWork.png"),
      buttonLeft: require("../assets/images/productCenter_slices/Group 1905.png"),
      buttonLeftBlue: require("../assets/images/productCenter_slices/arrow_left_blue.png"),
      buttonRight: require("../assets/images/productCenter_slices/arrow_right_grey.png"),
      buttonRightBlue: require("../assets/images/productCenter_slices/Group 1906.png"),
      arrowhead: require("../assets/images/home_slices/arrowhead.png"),

      banner: [
        {
          img: require("../assets/images/productCenter_slices/BossDI.png"),
        },
      ],
      carouselItems: [],
      currentIndex: 0,
      offset: 0,
      aboutUs: [
        {
          img: require("../assets/images/download.jpg"),
          path: "/about",
          title: "公司介绍",
          p: `广东壹公里数智科技有限公司,公司成立于1994年，至今已26年。是佛山地区较早专业从事弱电系统集成工程和软件开发服务的高新科技企业之一。自成立以来，公司以"诚信赢得客户、质量。取信客户、服务巩固客户”为宗旨。`,
        },
        {
          img: require("../assets/images/ziji.png"),
          path: "/ziji",
          title: "资质荣誉",
          p: `公司必须清醒地看到前进中的困难与挑战，正视自身存在的差距与不足，以更加坚定的信念、更加饱满的热情、更加务实的作风、
                    更加强大的合，共同谱写公司发展的新篇章，为公司提供强有力的售后服务与支持。`,
        },
      ],
      tabIndex: 0,
      tab: [
        {
          title: "运营服务",
          content: "我们提供全方位的数字化运营服务。",
          img: require("../assets/images/anli/智慧运维系统.jpg"),
        },
        {
          title: "咨询服务",
          content: "我们提供全面的业务咨询服务。",
          img: require("../assets/images/anli/zx1.jpg"),
        },
        {
          title: "集成服务",
          content: "我们提供端到端的系统集成服务。",
          img: require("../assets/images/anli/jc2.jpg"),
        },
      ],
      partner: [],
      anli: [
        {
          name: "智慧运维系统",
          img: require("../assets/images/anli/智慧运维系统.jpg"),
        },
        {
          name: "建筑可视化管理系统",
          img: require("../assets/images/anli/建筑可视化管理系统.jpg"),
        },
        {
          name: "终端安全管理系统",
          img: require("../assets/images/anli/终端安全管理系统.jpg"),
        },
        {
          name: "工业互联网标识数据服务系统",
          img: require("../assets/images/anli/工业互联网标识数据服务系统.png"),
        },
        {
          name: "智慧城市建设",
          img: require("../assets/images/anli/智慧城市建设.png"),
        },
        {
          name: "智能感知安防小区",
          img: require("../assets/images/anli/智能感知安防小区.jpg"),
        },
      ],
      caseList: [
        // {
        //   id: 1,
        //   title: "智慧政务",
        //   description:
        //     "“监管+执法”系列信息系统主要是面向市场监督管理局、城市管理和综合执法局、环保局等监管执法部门，构建移动巡查、移动执法、领导督办等业务应用模块，实现事件上报、在线监测、实时监管、预防犯错、执法有据，为监管执法队伍提供强大便利和有力保障。",
        // },
      ],
      bannerH: 0,
    };
  },
  created() {

  },
  computed: {
    images() {
      return images
    },
    backgroundStyle() {
      return {
        'background-image': `require(${this.backgroundImageUrl})`,
        // 其他的背景样式属性也可以在这里添加
      };
    }
  },
  async mounted() {

    // this.getBanners();
    this.getData();
    //案例获取
    let getArchives = await this.$http.get("/solution");
    if (getArchives.status == 200) {
      let list = getArchives.data;
      if (list.length > 5) {
        list.length = 5;
      }
      this.caseList = list;
    }

    //合作伙伴获取
    let cooperation = await this.$http.get("/cooperation");
    console.log(cooperation);
    if (cooperation.status == 200) {
      this.partner = cooperation.data;
    }
    // 动画重启
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      var wow = new WOW({
        live: false,
      });
      wow.init();
    });
    let w = 600 / 1920;
    let dw = document.documentElement.offsetWidth || document.innerHeight;
    this.bannerH = w * dw;
    console.log("高度", w * dw);
  },
  methods: {
    tryUse(){
      this.$router.push('/apply')
    },
    consultation(){
      this.$router.push('/frontserve')
    },
    docs(val) {
      this.$router.push("/quarterlyFile/programme/" + val.id);
    },
    scrollPrev() {
      if (this.currentIndex === 0) {
        // this.offset = -100 * (this.carouselItems.length - 1);
        this.toRightFlag=true;
        this.RightFlag=false;
        this.toLeftFlag=false;
        this.LeftFlag=true;
      } else {
        this.offset += 100;
        this.currentIndex--;
        this.toRightFlag=true;
        this.RightFlag=false;
      }
    },
    scrollNext() {
      if (this.currentIndex === this.carouselItems.length - 1) {

        // this.offset = 0;
        this.toLeftFlag=true;
        this.LeftFlag=false;
        this.toRightFlag=false;
        this.RightFlag=true;
      } else {
        this.currentIndex++;
        this.offset -= 100;
        this.toLeftFlag=true;
        this.LeftFlag=false;
      }
    },
    changeTab(tab) {
      this.activeTab = tab;
      console.log(tab)
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },//家电行业，家居行业等导航栏
    toggleModal() {
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    onSubmit() {
      this.toggleModal();
      alert("我们会第一时间联系你");
      console.log("提交");
    },
    async getData() {
      let _this = this;
      // this.getTitle();
      await _this.$http
          .get(`/AllProduct`, { params: _this.queryInfo })
          .then((e) => {
            console.log(e.data);
            if (e.data.code == 200) {
              this.carouselItems=e.data.data.product
              this.queryInfo.total = e.data.data.total;
              this.datas = [];
              // console.log(e.data.data.product)
              for (let index = 0; index < e.data.data.product.length; index++) {
                const element = e.data.data.product[index];
                let tag = element.product.split("、");
                element.tag = tag;
                this.datas.push(element);
              }
            }
          });
      // 动画重启
      this.$nextTick(() => {
        // 在dom渲染完后,再执行动画
        var wow = new WOW({
          live: false,
        });
        wow.init();
      });
    },

    getBanners() {
      this.$http.get("/banner/getBanner")
          .then((response) => {
            // 处理成功情况
            console.log(response.data.data);
            this.banner = response.data.data
          })
          .catch((error) => {
            // 处理错误情况
            console.log(error);
          })
          .then(function () {
            // 总是会执行
          });
    },
  },
};
</script>
<style scoped>
.image-button-min {
  background: linear-gradient(to right, #2840d4, #399ff7);
  color: white;
}
.image-button-min:focus{
  box-shadow: none;
  outline-color: transparent;
}
.top-title{
  font-weight: bold;color: white;font-size: 50px
}
.solution-title-img{
  font-size: 40px;font-weight: bold
}
.product-roll-div-image{
  position: relative;
  height: 900px;
  background-image: url('../assets/images/productCenter_slices/Rectangle 4556.png');
}
.imgBackground-product{
  margin-left: 10px;
  margin-right: 20px;
  background-image: url('../assets/images/productCenter_slices/Rectangle 4557.png');
  height: 320px;

}
.product-carousel {
  width: 100%;
  height: 550px;
}

.product-carousel-content {
  width: 400px;
  display: flex;
  transition: transform 0.5s ease-in-out;

}

.product-carousel-item {
  flex: 0 0 100%;
  width: 1000px;
  height: 200px;
  text-align: center;
}

.font-backImg{
  position: relative;
  height: 15px; /* 调整容器高度以适应内容 */
  width: 100px;
  background-image: url('../assets/images/productCenter_slices/Rectangle 4557.png');
  top: -10px;
  left: 0;
  z-index:1;
}
.text{
  z-index: 1;
}
.tab-header {

}
.tab {
  cursor: pointer;
  padding: 10px;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  border: 2px solid transparent;
  height: 50px;
  background: #F1F6FF;
  border-radius: 5px;
}
.tab.active {
  border: 2px solid #409EFF;
}
.tab-content-product div {
  display: none;
}
.tab-content-product div[style*='display: block'] {
  display: block;
}
.tab-content-product {
  position: relative;
  height: 100px;
  width: 100%;
}

.b-carousel-indicators {
  position: absolute;
  bottom: 0;
  right: 0;
}
.image-float-style{
  box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);
  margin: 40px 20px 40px 0px;
  background: white;height: 80px
}
.product-matrix-style{
  box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);
  margin: 0 20px 20px 0;
  padding: 0 0;
}
.custom.custom-tabs .el-tabs__header {
  margin-right: 29px;
}

.el-header, .el-footer {
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-aside {
  background-color: #D3DCE6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
  line-height: 160px;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

.image-button {
  background: linear-gradient(to right, #2840d4, #399ff7);
  color: white;
  width: 180px;
  height: 50px;
}
.image-button:focus{
  box-shadow: none;
  outline-color: transparent;
}

.image-button-min {
  background: linear-gradient(to right, #2840d4, #399ff7);
  color: white;
  width: 40px;

}

.up-one-title-font {
  font-size: 25px;
  font-weight: bold;
}

.up-one-font {
  font-size: 12px;
  font-weight: bold;
}
.product-mycol{
  height: 300px;
  width: 100%;
}
.mycol1{
  height: 420px;
}
.myrow{
  box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);
}
.my-tab-row{
  box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);
  height: 480px;
}
.one-dynamic-h1{
  font-size: 28px;
  font-weight: bold
}
.tag {
  background: white;
  color: black;
  border-radius: 20px;
  border-width: 1px;
  border-color: black;
  width: 120px;
}

.img-reason-one {
  height: auto;
  margin-bottom: 0px;
  position: relative;
  padding-bottom: 0px;
  background: white;
}
.img-reason-DI {
  height: 1150px;
  margin-bottom: 20px;
  position: relative;
  background-image: url('../assets/images/productCenter_slices/Rectangle 4556.png');
  //background: black;
}

.tag-min {
  background: white;
  color: black;
  border-radius: 20px;
  border-width: 1px;
  border-color: black;
  width: 70px;
}

.item {
  margin-top: 10px;
  margin-right: 40px;
  border-radius: 10px;
}

.container {
  position: relative;
  /* 确保子元素的绝对定位相对于该元素进行定位 */
}
.float-header{
    position: relative;
    top: -400px;
    left: 450px;
    z-index: 1;
    float: left;
    width: 100%;
}
.float-font-shallow{
    position: relative;
    top: -26px;
    left: 0px;
  margin-left: 10px;
}
.floating-product-div {
  position: relative;
  top: -240px;
  left: 30px;
  z-index: 1;
  float: left;
  width: 100%;
}
.float-reason-div {
  position: relative;
  top: -100px;
  left: 0;
  z-index: 1;
  float: left;
  width: 100%;
}

.float-DI-div {
  position: relative;
  top: -100px;
  left: 0;
  z-index: 1;
  float: left;
  width: 100%;
}

.float-input-company {
  position: relative;
  top: -500px;
  left: 0px;
  z-index: 1;
  float: left;
  width: 100%;
}

.float-success {
  position: relative;
  top: -40px;
  left: 45px;
  z-index: 1;
  float: left;
  width: 100%;
}

.float-customer {
  position: relative;
  top: -75px;
  left: -230px;
  z-index: 1;
  float: left;
  width: 100%;
}
.float-product-customer {
  position: relative;
  top: -75px;
  left: -300px;
  z-index: 1;
  float: left;
  width: 100%;
}
.float-product-reason-customer {
  position: relative;
  top: -170px;
  left: -305px;
  z-index: 1;
}
.float-DI-title {
  position: relative;
  top: 35px;
  left: -215px;
  z-index: 1;
  float: left;
  width: 100%;
}
.float-customer-product-title{
  position: relative;
  top: -130px;
  left: -145px;
  z-index: 1;
  float: left;
  width: 100%;
}
.float-product-due {
  position: relative;
  top: 35px;
  left: -180px;
  z-index: 1;
  float: left;
  width: 100%;
}
.title-logo{
  font-size: 40px;
  font-weight: bold
}
.float-contact {
  position: relative;
  top: -300px;
  left: 0px;
  z-index: 1;
  float: left;
  width: 100%;
}
.float-111-low {
  position: relative;
//top: -100px; left: 0px;
  z-index: 1;
  width: 100%;
  text-align: center;
//background: black;
}

.float-low {
  position: relative;
  z-index: 1;
  width: 100%;
  text-align: center;

}

.float-div {
  position: relative;
  top: -400px;
  left: 45px;
  z-index: 1;
  float: left;
  width: 100%;
}

.float-LONDOD {
  position: relative;
  top: -200px;
  left: -56px;
  z-index: 1;
  float: left;
  width: 100%
}

.float-div-WABON {
  position: relative;

  top: -400px;
  left: 0px;
  z-index: 2;
  float: left;
  width: 100%;
}
.cover-ALL-industry{
  width: 100%;
  height: 400px;
  text-align: center;
  background-image: url('../assets/images/home_slices/Rectangle 30@2x.png');
}
.float-title {
  position: relative;
  top: -80px;
  left: -260px;
  z-index: 2;
  float: left;
  width: 100%;
}

.float-div-inner {
  position: relative;
  top: -300px;
  left: 0px;
  z-index: 2;
  float: left;
  width: 100%;
}

.float-image-title {
  position: relative;
  top: -250px;
  left: 0px;
  z-index: 2;
  float: left;
  width: 100%;
}

.quote {
  width: 200px;
  height: 200px;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: white;
}

.bg-purple {
  background: white;
}

.bg-purple-light {
  background: white;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: white;
}
</style>
<style lang="scss" scoped>
.carousel-item {
  // height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 100%;
  }
}

@media (min-width: 1620px) {
  .container {
    max-width: 1620px;
  }
}

.textColor {
  position: relative;

  &::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    color: transparent;
    background-image: linear-gradient(
            90deg,
            #03a9f4,
            #ffeb3b,
            #f441a5,
            #03a9f4
    );
    background-clip: text;
    clip-path: ellipse(50px 50px at 0% 50%);
    animation: spotlight 2s infinite;
  }
}

@keyframes spotlight {
  0% {
    clip-path: ellipse(50px 50px at 0% 50%);
  }
  50% {
    clip-path: ellipse(50px 50px at 100% 50%);
  }
  100% {
    clip-path: ellipse(50px 50px at 0% 50%);
  }
}

.h1 {
  text-align: center;
  // color: #fff;
  font-size: 2rem;
  font-weight: 900;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.flex-centen {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mode {
  position: relative;
  overflow: hidden;

  img {
    height: 170px;
    width: 100%;
    display: block;
  }

  .description {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: -100%;
    left: 0;
    border-radius: 4px;
    padding: 22px 10px;
    padding: 20px;
    background-color: rgba($color: #000, $alpha: 0.5);
    transition: all 1s;
    color: #fff;
    font-size: 1rem;
  }

  &:hover {
    .description {
      bottom: 0;
    }
  }

  &.last {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    border: 1px solid;
    color: #409eff;
  }
}

.flexcenten {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

// .img-fluid{
//   max-width: 100%;
//   height: calc(100% - 1rem);
// }
</style>