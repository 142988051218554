// import '@babel/polyfill'
import promise from 'es6-promise' 
promise.polyfill()
// import 'babel-polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import backgroundImage from '@/assets/images/home_slices/Group 1754.png';

// 自定义引用
// import ss from "vue-jsonp"
import VueJsonp from 'vue-jsonp'
import common from './models/common.js'
import Regex from './models/Regex.js'
import Meta from 'vue-meta'

// 自定义组件
import ZoomlaFoot from './components/ZoomlaFoot.vue'
import ZoomlaKefu from './components/ZoomlaKefu.vue'
// import Zoomla_plus_iteam from './components/Zoomla_plus_iteam.vue'

// 自定义引用
import "zico/css/zico.min.css";
import "./assets/animate.min.css";
import { IconsPlugin } from 'bootstrap-vue'

// import { WOW } from "wowjs";
// 图片懒加载
// import VueLazyLoad from 'vue-lazyload'
// Vue.use(VueLazyLoad,{
//     error:'/assets/images/error.png',
//     loading:'/assets/images/loading.gif'
// })

Vue.use(ElementUI);
Vue.use(IconsPlugin);
Vue.config.productionTip = false;
Vue.component('ZoomlaFoot', ZoomlaFoot);
Vue.component('ZoomlaKefu', ZoomlaKefu);
Vue.use(VueJsonp);
Vue.use(common);
Vue.use(Regex);
Vue.use(Meta);
Vue.use(backgroundImage);

import http from "./axios";


import axios from "axios";
Vue.prototype.$axios =axios;
Vue.prototype.$http = http;
// console.log("process.env.VUE_APP_API_URL ============ " + JSON.stringify(process.env))
// console.log("process.env.VUE_APP_API_URL ============ " + process.env.VUE_APP_API_URL)
let apiUrl = process.env.VUE_APP_API_URL
if(apiUrl){
  Vue.prototype.$imgUrl = process.env.VUE_APP_API_URL + (process.env.VUE_APP_API_URL.endsWith('/') ? '' : '/') + "img/" || "/img/"
}else{
  Vue.prototype.$imgUrl = "/img/"
}
// console.log(process.env.VUE_APP_API_URL + (process.env.VUE_APP_API_URL.endsWith('/') ? '' : '/') + "img/" || "/img/");
// Vue.prototype.$imgUrl = process.env.VUE_APP_API_URL + (process.env.VUE_APP_API_URL.endsWith('/') ? '' : '/') + "img/" || "/img/"
// console.log("url",process.env.VUE_APP_API_URL)
new Vue({
  router,

  render: h => h(App)
}).$mount('#app')


// Vue.mixni({
//   computed: {},
//   methods: {
//     // 动画重启
//     animate() {
//       this.$nextTick(() => {
//         // 在dom渲染完后,再执行动画
//         var wow = new WOW({
//           live: false,
//         });
//         wow.init();
//       });
//     }
//   }
// })