<template>
  <div class="counseling">
    <div class="all">
      <ul>
        <li
          class="cls-sm"
          :class="{ blueBg: isHover1 }"
          @mouseover="handover1()"
          @mouseout="handout1()"
        >
          <a
            :href="isMobileDevice()?'mqqwpa://im/chat?chat_type=wpa&uin=842823494&version=1&src_type=web&web_src=oicqzone.com':'tencent://message/?uin=842823494&Site=http://vps.shuidazhe.com&Menu=yes'"
            style="color: #303a50"
          >
            <div class="top"></div>
            <div class="cls-img">
              <img src="../assets/images/Counseling/sms.png" />
            </div>
            <P>在线<br />客服</P>
          </a>
        </li>
        <li
          class="cls-sm"
          :class="{ blueBg: isHover2 }"
          @mouseover="handover2()"
          @mouseout="handout2()"
        >
          <div class="cls-img">
            <img src="../assets/images/Counseling/call.png" />
          </div>
          <P>电话<br />咨询</P>
          <div class="cls-out">
            <div class="cls-out-top">
              <P
                style="
                  text-indent: 2em;
                  font-size: 12px;
                  margin-top: 20px;
                  margin-bottom: 0px;
                  color: #303a50;
                "
                >客服电话</P
              >
              <p
                style="
                  text-indent: 1.5em;
                  color: #0139d0;
                  font-size: 16px;
                  font-weight: bold;
                  margin-top: 0px;
                "
              >
                0757-83785031
              </p>
            </div>
            <div class="cls-out-botton">
              <P
                style="
                  text-indent: 2em;
                  font-size: 12px;
                  color: #303a50;
                  margin-bottom: 0px;
                "
                >咨询顾问</P
              >
              <p
                style="
                  text-indent: 1.5em;
                  color: #1b212e;
                  margin-top: 0px;
                  font-size: 16px;
                  font-weight: bold;
                "
              >
                18680037498
              </p>
            </div>
          </div>
        </li>
        <li
          class="cls-sm"
          :class="{ blueBg: isHover3 }"
          @mouseover="handover3()"
          @mouseout="handout3()"
          @click="contactway3()"
        >
          <div class="cls-img">
            <img src="../assets/images/Counseling/assignment.png" />
          </div>
          <P>申请<br />试用</P>
        </li>
        <li
          class="cls-sm"
          :class="{ blueBg: isHover4 }"
          @mouseover="handover4()"
          @mouseout="handout4()"
        >
          <div class="cls-img">
            <img src="../assets/images/Counseling/weixin.png" />
          </div>
          <P>微信<br />咨询</P>
          <div v-show="isHover4" class="cls-out2">
            <div class="cls-img2">
              <img src="../assets/images/bossfront/壹公里公众号 1.png" alt="" />
            </div>
            <p style="">请扫码与壹公里联系</p>
          </div>
        </li>
        <li class="cls-sms" @click="scrollTop()">
          <div class="cls-hr">
            <hr width="30px" size="2" color="#DCE0E4" />
          </div>
          <div class="cls-img3"></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Counseling",
  data() {
    return {
      isHover1: false,
      isHover2: false,
      isHover3: false,
      isHover4: false,
    };
  },
  methods: {
    isMobileDevice() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
    handover1() {
      this.isHover1 = true;
    },
    handout1() {
      this.isHover1 = false;
    },
    handover2() {
      this.isHover2 = true;
    },
    handout2() {
      this.isHover2 = false;
    },
    handover3() {
      this.isHover3 = true;
    },
    handout3() {
      this.isHover3 = false;
    },
    handover4() {
      this.isHover4 = true;
    },
    handout4() {
      this.isHover4 = false;
    },
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    contactway3() {
      this.$router.push("/apply");
    },
  },
};
</script>

<style scoped lang="scss">
.counseling {
  z-index: 999;
  right: 28px;
  position: fixed;
  width: 56px;
  height: 344px;
  bottom: 40px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 4px 8px 0px rgba(44, 81, 154, 0.16);
  border-radius: 12px 12px 12px 12px;
  border: 1px solid #dce0e4;
}
.all {
  width: 56px;
  height: 344px;
  font-family: SF Pro Display-Bold, SF Pro Display;
  font-weight: bold;
  color: #1b212e;
  background: #ffffff;
  border-radius: 12px 12px 12px 12px;
}

.cls-sm {
  font-family: PingFang SC-Medium, PingFang SC;
  color: #303a50;
  right: 40px;
  // z-index: -2;
  display: inline-block;
  width: 56px;
  height: 72px;
  background: #ffffff;
  // opacity: 1;
  position: relative;
  text-align: center;
  font-size: 12px;
  border-radius: 12px 12px 12px 12px;
  cursor: pointer;
  .cls-out::before,
  .cls-out2::before {
    content: "";
    position: absolute;
    right: -30px;
    width: 30px;
    height: 100%;
  }
  &:hover .cls-out {
    display: block;
  }

  &:hover .cls-out2 {
    display: block;
  }
}

.cls-sms {
  right: 40px;
  display: inline-block;
  width: 56px;
  height: 56px;
  background-color: #ffffff;
  position: relative;
  text-align: center;
  font-size: 16px;
  border-radius: 12px 12px 12px 12px;
  cursor: pointer;
}
.cls-out {
  display: none;
  width: 180px;
  height: 154px;
  position: relative;
  text-align: left;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 8px 16px 0px rgba(44, 81, 154, 0.16);
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  border: 1px solid #dce0e4;
  float: right;
  bottom: 110px;
  right: 70px;
  font-family: SF Pro Display-Bold, SF Pro Display;
}

.cls-out2 {
  display: none;
  width: 180px;
  height: 203px;
  position: relative;
  color: black;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 8px 16px 0px rgba(44, 81, 154, 0.16);
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  border: 1px solid #dce0e4;
  float: right;
  bottom: 140px;
  right: 70px;
}

.cls-img {
  // z-index: 2;
  margin: 10px 16px 0px 16px;
  width: 24px;
  height: 24px;
  position: relative;
}

.cls-img2 {
  margin: 16px 16px 0px 16px;
  width: 148px;
  height: 148px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  position: relative;
}
.cls-img3 {
  margin: -8px 16px 16px 16px;
  background-image: url("../assets/images/Counseling/vertical_align_top.png");
  width: 24px;
  height: 24px;
  position: relative;
}
.cls-img3:hover {
  background-image: url("../assets/images/Counseling/vertical_align_top(1).png");
}

.cls-text {
  z-index: 20;
  width: 132px;
  height: 13px;
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #303a50;
  line-height: 13px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
}

.blueBg {
  background: linear-gradient(176deg, #0139d0 0%, #00a3ff 100%);
  color: #ffffff;
}
</style>